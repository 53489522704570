var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" List of "+_vm._s(_vm.qrType === _vm.QR_TYPES.CONFIDENCE ? 'Confidence' : 'Computer Science')+" QR Codes "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('add-qr')}}},[_vm._v("Add QR Code")])],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.filteredQrCodes},scopedSlots:_vm._u([{key:"item.qrHash",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.copyToClipboard(item.qrHash)}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1),_c('span',[_vm._v(_vm._s(item.qrHash))])]}},{key:"item.qrImageUrl",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-img',{attrs:{"src":item.qrImageUrl,"max-width":"100","max-height":"100","contain":""}}),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openImageInNewTab(item.qrImageUrl)}}},[_c('v-icon',[_vm._v("mdi-fullscreen")])],1)],1)]}},{key:"item.redirectUrl",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.copyToClipboard(item.redirectUrl)}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1),_c('span',{staticClass:"ml-2"},[_c('v-btn',{attrs:{"text":"","href":item.redirectUrl,"target":"_blank"}},[_vm._v(" Visit URL ")])],1)],1)]}},{key:"item.grade",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getGradeAndSubjectDetails(item.grade, item.subject).grade.displayName))])]}},{key:"item.subject",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getGradeAndSubjectDetails(item.grade, item.subject).subject.name))])]}},{key:"item.year",fn:function(ref){
var item = ref.item;
return (_vm.qrType === _vm.QR_TYPES.CONFIDENCE)?[_c('span',[_vm._v(_vm._s(item.year))])]:undefined}},{key:"item.block",fn:function(ref){
var item = ref.item;
return [(item.block && item.block.bId)?_c('span',[_vm._v(_vm._s(item.block.bId)+" - ")]):_vm._e(),(item.block && item.block.title)?_c('span',[_vm._v(" "+_vm._s(item.block.title))]):_vm._e()]}},{key:"item.question",fn:function(ref){
var item = ref.item;
return (_vm.qrType === _vm.QR_TYPES.CONFIDENCE)?[_c('span',[_vm._v(_vm._s(item.question))])]:undefined}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.editQr(item)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('template',{slot:"top"},[_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search","append-icon":"mdi-magnify","placeholder":"Search QR Hash, Grade, Subject, Block, BlockId..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }