<template>
  <div :class="$router.currentRoute.name + ' app-header'">
    <v-app-bar color="primary" dark>
      <v-btn style="margin-left: 65px;" @click="goToHome" icon>
        <v-icon>mdi-widgets</v-icon>
      </v-btn>
      <v-toolbar-title>XSEED Content Entry System</v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="change-page-select-wrapper">
        <v-select
          :width="220"
          class="change-page-select"
          :items="menuItemsBasedOnUsers"
          item-text="title"
          item-value="title"
          @change="goToPage(menuItemsBasedOnUsers)"
          v-model="currentRoute"
          >{{ currentRoute }}</v-select
        >
      </div>
      <v-btn text style="margin-left: 40px;">{{ (user || {}).name || '-' }}</v-btn>
      <v-btn text @click="logout" style="margin-right: 40px; margin-left: 10px;">Logout</v-btn>
    </v-app-bar>
  </div>
</template>

<script>
import { reLogin } from '../utils/auth';
import { mapState } from 'vuex';

export default {
  name: 'AppHeader',
  data() {
    return {
      menuItems: [
        { title: 'School Program', link: '/projects', isOnlyForAdmins: false },
        { title: 'School Program', link: '/lp_form', isOnlyForAdmins: false },
        { title: 'O-Program', link: '/micro_topics', isOnlyForAdmins: false },
        { title: 'Users', link: '/users', isOnlyForAdmins: true },
        { title: 'Block Map', link: '/select_subject', isOnlyForAdmins: false },
        {
          title: 'Model Answer Guide Download',
          link: '/model_answer_guide_download',
          isOnlyForAdmins: false,
        },
        { title: 'Block Map Status', link: '/blockmaps_status', isOnlyForAdmins: false },
        { title: 'Learnometer', link: '/learnometer', isOnlyForAdmins: false },
        { title: 'QR Management', link: '/qr', isOnlyForAdmins: true },
      ],
      menuItemsBasedOnUsers: [],
      currentRoute: '',
    };
  },
  created() {
    this.setCurrentPageRoute();
    if (this.isAdmin == false && this.$route.path == '/users') {
      this.$router.push('/');
      this.setCurrentPageRoute();
    }
  },
  computed: {
    ...mapState({
      isAdmin: (state) => state.auth.isAdmin,
      isTrainingAdmin: (state) => state.auth.isTrainingAdmin,
    }),
  },
  watch: {
    isAdmin: {
      immediate: true,
      handler() {
        this.setMenuItemsBasedOnUser();
      },
    },
    isTrainingAdmin: {
      immediate: true,
      handler(value) {
        if (value) {
          this.menuItemsBasedOnUsers.push({
            title: 'XSEED Academy',
            link: '/xseed_academy',
            isOnlyForAdmins: false,
          });
          if (this.$route.path.includes('xseed_academy')) {
            this.currentRoute = 'XSEED Academy';
          }
        }
      },
    },
  },
  methods: {
    setMenuItemsBasedOnUser() {
      if (this.isAdmin) {
        this.menuItemsBasedOnUsers = this.menuItems;
      } else {
        this.menuItemsBasedOnUsers = this.menuItems.filter((menu) => menu.isOnlyForAdmins == false);
      }
    },
    setCurrentPageRoute() {
      let routePath = this.$route.path;
      if (routePath === '/') {
        this.currentRoute = this.menuItems[0];
      } else {
        this.menuItems.forEach((item) => {
          if (routePath.includes(item.link)) {
            this.currentRoute = item;
          }
        });
      }
    },
    goToHome() {
      this.setCurrentPageRoute();
      this.$router.push({ name: 'Projects' });
    },
    logout() {
      reLogin();
    },
    goToPage(link) {
      const item = link.filter((link) => {
        return link.title.includes(this.currentRoute);
      });
      if (this.$route.path != item[0].link) {
        this.$router.push({ path: item[0].link });
      }
    },
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style>
.app-header {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
}

.change-page-select .v-label:not(.v-label--active) {
  color: #242424;
  font-size: 14px;
  font-weight: 500;
  transform: translateY(-5px);
}
.change-page-select {
  font-size: 14px;
  font-weight: 500;
  color: black;
}
</style>
