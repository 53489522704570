<template>
  <div>
    <v-row v-if="schema.aim.show" dense class="item section">
      <v-col cols="3" class="summary-section-pentagon">
        <div class="bubble">
          <v-label>Aim</v-label>
        </div>
      </v-col>
      <v-col cols="9">
        <RichTextEditor
          class="mgn-Tm"
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="isDiffChangedForJsonPath('aim.summary')"
          fieldIdentifier="aim.summary"
          v-model="lessonPlanData.aim.summary"
          placeholder="Write Aim Summary"
          :showValidationError="!schema.aim.isSummaryOptional"
          :readonly="!isNotReadOnly"
          :counter="schema.aim.summaryMaxLength"
          :includeOnlyToolBarActions="BASIC_TOOLS"
        />
      </v-col>
    </v-row>
    <v-row v-if="schema.action.show || schema.action.showSummarySection" dense class="item section">
      <v-col cols="3" class="summary-section-pentagon">
        <div class="bubble">
          <v-label>Action</v-label>
        </div>
      </v-col>
      <v-col cols="9">
        <RichTextEditor
          class="mgn-Tm"
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="isDiffChangedForJsonPath('action.summary')"
          fieldIdentifier="action.summary"
          v-model="lessonPlanData.action.summary"
          placeholder="Write Action Summary"
          :showValidationError="!schema.action.isSummaryOptional"
          :readonly="!isNotReadOnly"
          :counter="schema.action.summaryMaxLength"
          :includeOnlyToolBarActions="BASIC_TOOLS"
        />
      </v-col>
    </v-row>
    <v-row dense class="item section" v-show="schema.analysis.show || schema.analysis.showSummarySection">
      <v-col cols="3" class="summary-section-pentagon">
        <div class="bubble">
          <v-label>Analysis</v-label>
        </div>
      </v-col>
      <v-col cols="9">
        <RichTextEditor
          class="mgn-Tm"
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="isDiffChangedForJsonPath('analysis.summary')"
          fieldIdentifier="analysis.summary"
          v-model="lessonPlanData.analysis.summary"
          placeholder="Write Analysis Summary"
          :showValidationError="!schema.analysis.isSummaryOptional"
          :readonly="!isNotReadOnly"
          :counter="schema.analysis.summaryMaxLength"
          :includeOnlyToolBarActions="BASIC_TOOLS"
        />
      </v-col>
    </v-row>
    <v-row dense class="item section" v-if="schema.application.show && schema.application.showSummarySection">
      <v-col cols="3" class="summary-section-pentagon">
        <div class="bubble">
          <v-label>Application</v-label>
        </div>
      </v-col>
      <v-col cols="9" v-if="isQuestionLesson">
        <v-row dense class="section" v-if="lessonPlanData.application.classwork">
          <v-col>
            <RichTextEditor
              class="mgn-Tm"
              :canShowDiffIcon="canShowDiffIcon"
              :isDiffChanged="isDiffChangedForJsonPath('application.summaryData.classwork')"
              fieldIdentifier="application.summaryData.classwork"
              v-model="lessonPlanData.application.summaryData.classwork"
              placeholder="Classwork"
              :includeOnlyToolBarActions="BASIC_TOOLS"
              :showValidationError="!schema.application.isSummaryOptional"
              :readonly="!isNotReadOnly"
              :counter="schema.application.classworkSummaryMaxLength"
            />
          </v-col>
        </v-row>
        <v-row dense class="section" v-if="lessonPlanData.application.homework">
          <v-col>
            <RichTextEditor
              class="mgn-Tm"
              :canShowDiffIcon="canShowDiffIcon"
              :isDiffChanged="isDiffChangedForJsonPath('application.summaryData.homework')"
              fieldIdentifier="application.summaryData.homework"
              v-model="lessonPlanData.application.summaryData.homework"
              placeholder="Homework"
              :includeOnlyToolBarActions="BASIC_TOOLS"
              :showValidationError="!(schema.application.isSummaryOptional || isHomeworkOptional)"
              :readonly="!isNotReadOnly"
              :counter="schema.application.homeworkSummaryMaxLength"
            />
          </v-col>
        </v-row>
        <v-row dense class="section" v-if="lessonPlanData.application.mental_math">
          <v-col>
            <RichTextEditor
              class="mgn-Tm"
              :canShowDiffIcon="canShowDiffIcon"
              :isDiffChanged="isDiffChangedForJsonPath('application.summaryData.mental_math')"
              fieldIdentifier="application.summaryData.mental_math"
              v-model="lessonPlanData.application.summaryData.mental_math"
              placeholder="Mental Math"
              :includeOnlyToolBarActions="BASIC_TOOLS"
              :showValidationError="!schema.application.isSummaryOptional"
              :readonly="!isNotReadOnly"
              :counter="schema.application.mentalMathSummaryMaxLength"
            />
          </v-col>
        </v-row>
        <v-row dense class="section" v-if="lessonPlanData.application.problem_solving">
          <v-col>
            <RichTextEditor
              class="mgn-Tm"
              :canShowDiffIcon="canShowDiffIcon"
              :isDiffChanged="isDiffChangedForJsonPath('application.summaryData.problem_solving')"
              fieldIdentifier="application.summaryData.problem_solving"
              v-model="lessonPlanData.application.summaryData.problem_solving"
              placeholder="Problem Solving"
              :includeOnlyToolBarActions="BASIC_TOOLS"
              :showValidationError="!schema.application.isSummaryOptional"
              :readonly="!isNotReadOnly"
              :counter="schema.application.problemSolvingSummaryMaxLength"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="9" v-else>
        <v-row dense class="section" v-if="schema.application.showClasswork">
          <v-col>
            <RichTextEditor
              class="mgn-Tm"
              :canShowDiffIcon="canShowDiffIcon"
              :isDiffChanged="isDiffChangedForJsonPath('application.summaryData.classwork')"
              fieldIdentifier="application.summaryData.classwork"
              v-model="lessonPlanData.application.summaryData.classwork"
              placeholder="Classwork"
              :includeOnlyToolBarActions="BASIC_TOOLS"
              :showValidationError="!schema.application.isSummaryOptional"
              :readonly="!isNotReadOnly"
              :counter="schema.application.classworkSummaryMaxLength"
            />
          </v-col>
        </v-row>
        <v-row dense class="section" v-if="schema.application.showHomeWork || Array.isArray(lessonPlanData.application.lab_homework) || Array.isArray(lessonPlanData.application.lab_homework.instructions)">
          <v-col>
            <RichTextEditor
              class="mgn-Tm"
              :canShowDiffIcon="canShowDiffIcon"
              :isDiffChanged="isDiffChangedForJsonPath('application.summaryData.homework')"
              fieldIdentifier="application.summaryData.homework"
              v-model="lessonPlanData.application.summaryData.homework"
              placeholder="Homework"
              :includeOnlyToolBarActions="BASIC_TOOLS"
              :showValidationError="!(schema.application.isSummaryOptional || isHomeworkOptional)"
              :readonly="!isNotReadOnly"
              :counter="schema.application.homeworkSummaryMaxLength"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row dense class="item section" v-if="canShowEvaluateAndGrade && schema.assessment.show && schema.assessment.showSummarySection">
      <v-col cols="3" class="summary-section-pentagon">
        <div class="bubble">
          <v-label>Assessment</v-label>
        </div>
      </v-col>
      <v-col cols="9">
        <RichTextEditor
          class="mgn-Tm"
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="isDiffChangedForJsonPath('assessment.summaryData.approaching')"
          fieldIdentifier="assessment.summaryData.approaching"
          v-model="lessonPlanData.assessment.summaryData.approaching"
          placeholder="Approaching"
          :includeOnlyToolBarActions="BASIC_TOOLS"
          :showValidationError="!schema.assessment.isSummaryOptional"
          :readonly="!isNotReadOnly"
          :counter="schema.assessment.approachingSummaryMaxLength"
        />
      </v-col>
    </v-row>
    <v-row dense class="section" v-if="canShowEvaluateAndGrade && schema.assessment.show && schema.assessment.showSummarySection">
      <v-col cols="3"></v-col>
      <v-col cols="9">
        <RichTextEditor
          class="mgn-Tm"
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="isDiffChangedForJsonPath('assessment.summaryData.meeting')"
          fieldIdentifier="assessment.summaryData.meeting"
          v-model="lessonPlanData.assessment.summaryData.meeting"
          placeholder="Meeting"
          :includeOnlyToolBarActions="BASIC_TOOLS"
          :showValidationError="!schema.assessment.isSummaryOptional"
          :readonly="!isNotReadOnly"
          :counter="schema.assessment.meetingSummaryMaxLength"
        />
      </v-col>
    </v-row>
    <v-row dense class="section" v-if="canShowEvaluateAndGrade && schema.assessment.show && schema.assessment.showSummarySection">
      <v-col cols="3"></v-col>
      <v-col cols="9">
        <RichTextEditor
          class="mgn-Tm"
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="isDiffChangedForJsonPath('assessment.summaryData.exceeding')"
          fieldIdentifier="assessment.summaryData.exceeding"
          v-model="lessonPlanData.assessment.summaryData.exceeding"
          placeholder="Exceeding"
          :includeOnlyToolBarActions="BASIC_TOOLS"
          :showValidationError="!schema.assessment.isSummaryOptional"
          :readonly="!isNotReadOnly"
          :counter="schema.assessment.exceedingSummaryMaxLength"
        />
      </v-col>
    </v-row>
    <v-row dense class="item section" v-if="!canShowEvaluateAndGrade && schema.assessment.show && schema.assessment.showSummarySection">
      <v-col cols="3" class="summary-section-pentagon">
        <div class="bubble">
          <v-label>Assessment</v-label>
        </div>
      </v-col>
      <v-col cols="9">
        <RichTextEditor
          class="mgn-Tm"
          :canShowDiffIcon="canShowDiffIcon"
          :isDiffChanged="isDiffChangedForJsonPath('assessment.summaryData.overall')"
          fieldIdentifier="assessment.summaryData.overall"
          v-model="lessonPlanData.assessment.summaryData.overall"
          placeholder="Overall"
          :includeOnlyToolBarActions="BASIC_TOOLS"
          :showValidationError="!schema.assessment.isSummaryOptional"
          :readonly="!isNotReadOnly"
          :counter="schema.assessment.overallSummaryMaxLength"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AppTexts from '@/constants/AppTexts';
import Errors from '@/constants/Errors';
import { LP_FORM_RENDERER_TYPE_ART_PLAY_STORY_CIRCLE_TYPE } from '@/constants/LpForm';
import RichTextEditor, { BASIC_TOOLS } from '@/components/RTE/RichTextEditor';
import { get } from 'lodash';

export default {
  name: 'SummarySection',
  components: {
    RichTextEditor,
  },
  computed: {
    isHomeworkOptional() {
      return this.schema.application.isHomeworkOptional;
    },
  },
  data() {
    return {
      AppTexts,
      LP_FORM_RENDERER_TYPE_ART_PLAY_STORY_CIRCLE_TYPE,
      BASIC_TOOLS,
      Errors,
    };
  },
  props: {
    summaryData: {
      type: Object,
      required: true,
    },
    updateSummaryTextToStore: {
      type: Function,
      required: false,
      default: () => {},
    },
    diff: {
      type: Object,
      required: true,
    },
    canShowDiffIcon: {
      type: Boolean,
      required: true,
    },
    canShowEvaluateAndGrade: {
      type: Boolean,
      default: true,
    },
    isNotReadOnly: {
      type: Boolean,
      required: false,
      default: true,
    },
    schema: {
      type: Object,
      required: true,
    },
    isQuestionLesson: {
      type: Boolean,
      required: false,
      default: false,
    },
    lessonPlanData: {
      type: Object,
      required: false,
    },
  },
  methods: {
    isDiffChangedForJsonPath(jsonPath) {
      try {
        if (this.canShowDiffIcon) {
          const data = get(this.diff, jsonPath);

          return data.isChanged;
        }

        return false;
      } catch (error) {
        return false;
      }
    },
  },
};
</script>
<style scoped>
.pad-Ts {
  padding-top: 10px;
}
.summary-section-pentagon {
  text-align: center;
  text-transform: uppercase;
  color: #242424;
  font-size: 20px;
}
.bubble {
  position: relative;
  width: 140px;
  height: 50px;
  padding: 0px;
  background: #b5e9e4;
}

.bubble:after {
  content: '';
  position: absolute;
  top: 12px;
  right: -37px;
  transform: rotate(-90deg);
  border-style: solid;
  border-width: 25px 25px 0;
  border-color: #b5e9e4 transparent;
  display: block;
  width: 0;
  z-index: 1;
}
.bubble .v-label {
  top: 10px;
  font-size: 20px;
  color: #242424;
  padding: 0px 6px;
}
.row.item {
  margin-top: 10px;
}
.section {
  padding: 0px;
}
</style>
