<template>
  <v-container>
    <v-card>
      <v-card-title>
        {{ qrData.redirectUrl ? 'Edit' : 'Add New' }}
        {{ qrType === QR_TYPES.CONFIDENCE ? 'Confidence' : 'Computer Science' }} QR Code
      </v-card-title>

      <v-form ref="form" v-model="valid">
        <!-- Conditionally show the form fields based on qrType -->
        <v-card-text>
          <!-- Show only for non-confidence QR Type -->
          <template v-if="qrType !== QR_TYPES.CONFIDENCE">
            <!-- Product Selection -->
            <v-select
              v-model="formData.product"
              :items="products"
              item-text="name"
              item-value="id"
              label="Product"
              required
              return-object
              :rules="[(v) => !!v || 'Product is required']"
              @change="onProductChange"
            ></v-select>

            <!-- Board Selection -->
            <v-select
              v-model="formData.board"
              :items="boards"
              item-text="name"
              item-value="id"
              label="Board"
              required
              return-object
              :rules="[(v) => !!v || 'Board is required']"
              @change="onBoardChange"
            ></v-select>
          </template>

          <!-- Grade Selection (always shown) -->
          <v-select
            v-model="formData.grade"
            :items="standards"
            item-text="displayName"
            item-value="id"
            label="Grade"
            required
            return-object
            :rules="[(v) => !!v || 'Grade is required']"
            @change="onGradeChange"
          ></v-select>

          <!-- Subject Selection (always shown) -->
          <v-select
            v-model="formData.subject"
            :items="getFilteredSubjects()"
            item-text="name"
            item-value="id"
            label="Subject"
            required
            return-object
            :rules="[(v) => !!v || 'Subject is required']"
            @change="onSubjectChange"
          ></v-select>

          <!-- Year Selection (only for Confidence QR Type) -->
          <v-select
            v-if="qrType === QR_TYPES.CONFIDENCE"
            v-model="formData.year"
            :items="years"
            label="Year"
            required
            :rules="[(v) => !!v || 'Year is required']"
          ></v-select>

          <!-- Block Selection (always shown) -->
          <v-select
            v-model="formData.block"
            :items="localBlocks"
            :item-text="blockItemText"
            item-value="id"
            label="Block"
            required
            return-object
            :rules="[(v) => !!v || 'Block is required']"
          ></v-select>

          <!-- Redirect URL (only shown for non-confidence QR Type) -->
          <v-text-field
            v-if="qrType !== QR_TYPES.CONFIDENCE"
            v-model="formData.redirectUrl"
            label="Redirect URL"
            required
            :rules="[(v) => !!v || 'Redirect URL is required']"
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-btn
            v-if="!qrData.redirectUrl"
            color="primary"
            @click="() => this.submitForm(false)"
            :disabled="!valid || !formData.block"
            >Generate QR</v-btn
          >
          <v-btn
            v-else
            color="primary"
            @click="() => this.submitForm(true)"
            :disabled="!valid || !formData.block"
            >Update QR Mapping</v-btn
          >
          <v-btn text @click="$emit('cancel')">Cancel</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { QR_TYPES } from '../../constants/qrManagement';

export default {
  name: 'QRForm',
  props: {
    qrType: {
      type: String,
      required: true,
    },
    qrData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      QR_TYPES,
      valid: false,
      formData: {
        product: '',
        board: '',
        grade: '',
        subject: '',
        block: '',
        year: '',
        redirectUrl: '',
      },
      localBlocks: [], // Local variable to store blocks
      years: ['2023', '2024', '2025', '2026', '2027', '2028', '2029'],
    };
  },
  computed: {
    ...mapState({
      standards() {
        return this.qrType === QR_TYPES.CONFIDENCE
          ? this.$store.state.qrManagement.confidenceGrades
          : this.$store.state.standards || [];
      },
      products: (state) => state.products || [],
      boards: (state) => state.boards || [],
    }),
  },
  watch: {
    qrData: {
      immediate: true,
      handler(newData) {
        if (newData) {
          const grade = this.standards.find((grade) => grade.id === newData.grade) || {
            subjects: [],
          };
          const subject = grade.subjects.find((subject) => subject.id === newData.subject);
          // Prepopulate formData with the data from qrData when editing
          this.formData = {
            ...newData,
            grade: { id: grade.id, name: grade.name, displayName: grade.displayName },
            subject: subject,
          };
        }
        this.fetchBlocks();
      },
    },
  },
  methods: {
    ...mapActions([
      'fetchAllBlocksForSelectedProductBoardStandardSubject',
      'fetchConfidenceBlocksForQR',
    ]),

    // Handle when Product is updated
    onProductChange() {
      this.formData.board = '';
      this.formData.grade = '';
      this.formData.subject = '';
      this.formData.block = '';
      this.localBlocks = [];
      this.checkFormCompletion();
    },

    // Handle when Board is updated
    onBoardChange() {
      this.formData.grade = '';
      this.formData.subject = '';
      this.formData.block = '';
      this.localBlocks = [];
      this.checkFormCompletion();
    },

    // Handle when Grade is updated
    onGradeChange() {
      this.formData.subject = '';
      this.formData.block = '';
      this.localBlocks = [];
      this.checkFormCompletion();
    },

    // Handle when Subject is updated
    onSubjectChange() {
      this.localBlocks = [];
      this.formData.block = '';
      this.fetchBlocks();
      this.checkFormCompletion();
    },

    blockItemText(block) {
      if (block && block.bId && block.title) {
        return `${block.bId} - ${block.title}`;
      } else if (block && block.title) {
        return block.title;
      }
      return '';
    },

    getFilteredSubjects() {
      const selectedGrade = this.formData.grade;
      const grade = this.standards.find((grade) => grade.id === selectedGrade.id);
      if (!grade || !grade.subjects) {
        return [];
      }
      return grade.subjects;
    },

    // Fetch blocks when all necessary fields are selected
    async fetchBlocks() {
      console.log('Fetching blocks');

      const { product, board, grade, subject } = this.formData;
      if (this.qrType === QR_TYPES.CONFIDENCE) {
        if (grade && subject) {
          const payload = {
            standardId: grade.id,
            subjectId: subject.id,
          };
          try {
            const blocks = await this.fetchConfidenceBlocksForQR(payload);
            this.localBlocks = blocks;
          } catch (error) {
            console.error('Error fetching blocks:', error);
            this.localBlocks = []; // Reset on error
          }
        } else {
          this.localBlocks = []; // Reset if required fields are not selected
        }
      } else {
        if (product && board && grade && subject) {
          const payload = {
            productId: product.id,
            boardName: board.name,
            standardId: grade.id,
            subjectId: subject.id,
          };

          try {
            const blocks = await this.fetchAllBlocksForSelectedProductBoardStandardSubject(payload);
            this.localBlocks = blocks;
          } catch (error) {
            console.error('Error fetching blocks:', error);
            this.localBlocks = []; // Reset on error
          }
        } else {
          this.localBlocks = []; // Reset if required fields are not selected
        }
      }
    },

    // Ensure all required fields are selected before enabling the button
    checkFormCompletion() {
      this.valid = !!(
        this.formData.product &&
        this.formData.board &&
        this.formData.grade &&
        this.formData.subject
      );
    },

    // Handle form submission
    submitForm(isEditFormData = false) {
      if (this.$refs.form.validate()) {
        const payload = {
          qrHash: this.qrData.qrHash,
          qrType: this.qrType,
          grade: this.formData.grade.id,
          subject: this.formData.subject.id,
          year: this.formData.year,
          block: this.formData.block,
          redirectUrl: this.formData.redirectUrl,
          board: this.formData.board,
          product: this.formData.product,
        };
        this.$emit(isEditFormData ? 'update' : 'submit', payload);
      }
    },
  },
};
</script>

<style scoped>
.v-card {
  margin-top: 20px;
}
</style>
