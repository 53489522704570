<template>
  <v-container v-if="isAdmin">
    <v-card>
      <v-card-title>
        QR Management
      </v-card-title>

      <v-tabs v-model="activeTab" background-color="primary" dark>
        <v-tab disabled>Confidence QR</v-tab>
        <v-tab>Computer Science QR</v-tab>
      </v-tabs>

      <v-tabs-items v-model="activeTab">
        <!-- Tab for Confidence QR List -->
        <v-tab-item>
          <QRList
            :qrType="QR_TYPES.CONFIDENCE"
            @add-qr="showForm(QR_TYPES.CONFIDENCE)"
            :onEditQRMapping="onEditQRMapping"
          />
        </v-tab-item>

        <!-- Tab for Computer Science QR List -->
        <v-tab-item>
          <QRList
            :qrType="QR_TYPES.COMPUTER_SCIENCE"
            @add-qr="showForm(QR_TYPES.COMPUTER_SCIENCE)"
            :onEditQRMapping="onEditQRMapping"
          />
        </v-tab-item>
      </v-tabs-items>

      <!-- QR Form Modal -->
      <v-dialog v-model="showQrForm" max-width="600px">
        <QRForm
          :qrType="formQrType"
          :qrData="formQrData"
          @submit="handleFormSubmit"
          @update="handleFormDataUpdate"
          @cancel="closeForm"
        />
      </v-dialog>
    </v-card>
  </v-container>
  <UnAuthorizedCard
    v-else
    subTitleText="Unfortunately, you do not have the necessary permissions to manage the QR Management Module."
  />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import QRForm from './QRForm.vue';
import QRList from './QRList.vue';
import UnAuthorizedCard from '../Confidence/UnAuthorizedCard.vue';
import { QR_TYPES } from '../../constants/qrManagement';

export default {
  name: 'QRManagementPage',
  data() {
    return {
      QR_TYPES,
      activeTab: 1,
      showQrForm: false,
      formQrType: '',
      formQrData: null,
    };
  },
  components: {
    QRList,
    QRForm,
    UnAuthorizedCard,
  },
  computed: {
    ...mapState({
      isAdmin: (state) => state.auth.isAdmin,
    }),
  },
  watch: {
    // Watch for tab changes to reset form and preview
    activeTab() {
      this.resetFormAndPreview();
    },
  },
  methods: {
    ...mapActions(['addQrCode', 'updateQrCode', 'getCurrentUserAuthPermissions']),
    // Show form for adding a new QR
    showForm(qrType) {
      this.formQrType = qrType;
      this.formQrData = null; // Reset form data when adding a new QR
      this.clearFormData(); // Ensure the form data is completely reset
      this.showQrForm = true;
    },
    // Show form with prepopulated data for editing
    onEditQRMapping(item) {
      this.formQrType = item.qrType;
      this.formQrData = item; // Prepopulate form data when editing
      this.showQrForm = true;
    },
    // Clear the form data
    clearFormData() {
      this.formQrData = {
        grade: '',
        subject: '',
        year: '',
        block: '',
        redirectUrl: '',
      };
    },
    // Handle form submission
    handleFormSubmit(qrData) {
      console.log(qrData);
      this.addQrCode(qrData);
      this.showQrForm = false;
      this.showQrPreview = true;
    },
    handleFormDataUpdate(qrData) {
      console.log(qrData);
      this.updateQrCode(qrData);
      this.showQrForm = false;
      this.showQrPreview = true;
    },
    closeForm() {
      this.resetFormAndPreview();
    },
    // Reset form and preview when switching tabs or manually closing
    resetFormAndPreview() {
      this.showQrForm = false;
      this.formQrType = '';
      this.clearFormData(); // Reset form data
    },
  },
  created() {
    this.getCurrentUserAuthPermissions();
  },
};
</script>

<style scoped>
.v-tabs {
  margin-bottom: 20px;
}
</style>
