export default function rte(props, model) {
  return {
    name: 'rte',
    model,
    props: {
      canShowDiffIcon: props.canShowDiffIcon,
      isDiffChanged: props.isDiffChanged,
      fieldIdentifier: props.fieldIdentifier,
      showValidationError: props.showValidationError,
      placeholder: props.placeholder,
      readonly: !props.isNotReadOnly,
      counter: props.counter,
      includeOnlyToolBarActions: [
        'unorderedList',
        'orderedList',
        'bold',
        'italic',
        'underline',
        'superscript',
        'subscript',
        'equation',
        'fullScreen',
      ],
    },
  };
}
