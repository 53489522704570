import { baseMixin } from './base';
import { validateAssetAuthorized } from '@/utils';
import WhatWeLearned from '@/components/lessonPlanTemplate/classroom/WhatWeLearned';

export const classRoomLessonMixin = {
  mixins: [baseMixin],
  components: {
    WhatWeLearned,
  },
  data() {
    return {
      Schema: {
        aim: {
          showKeyQuestion: true,
          maxResourcesLength: 5,
          resourceMaxLength: 5,
        },
        action: {
          maxNoOfSteps: 100,
          maxNoOfSubStepsInStep: 100,
          maxSumOfStepsAndSubSteps: 500,
          allowedTimes: ['8 mins', '10 mins', '12 mins', '13 mins', '15 mins'],
          showSwitchableLayout: true,
          showLegacyComponentSwitcher: true,
          configurableMultiSlide: {
            show: true,
            maxSlidesCount: 10,
          },
        },
        analysis: {
          showReadXSEEDContentBookCheckbox: false,
          showCodingConnection: true,
          maxNoOfSteps: 100,
          maxNoOfSubStepsInStep: 100,
          maxSumOfStepsAndSubSteps: 500,
          showSwitchableLayout: true,
          showLegacyComponentSwitcher: true,
          discussion: {
            configurableMultiSlide: {
              show: true,
              maxSlidesCount: 5,
            },
          },
          explanation: {
            configurableMultiSlide: {
              show: true,
              maxSlidesCount: 5,
            },
          },
          allowedTimes: ['10 mins', '12 mins', '13 mins', '15 mins'],
        },
        application: {
          showLegacyClassWorkHomeWork: true,
          showClassWorkHomeWork: false,
          showDifferentiationTips: true,
          configurableMorePractice: {
            show: true,
            title: 'Thinking Out Loud',
            paragraphTextKey: 'application.thinking_out_loud.paragraph_text',
            items: 'application.thinking_out_loud',
            leafNode: 'thinking_out_loud',
            maxNoOfSteps: 100,
            maxNoOfSubStepsInStep: 100,
            maxSumOfStepsAndSubSteps: 500,
          },
          showLegacyComponentSwitcher: true,
          configurableMultiSlide: {
            show: true,
            maxSlidesCount: 2,
            slideLayoutCheckPath: 'application.thinking_out_loud.instructions.0',
            subPath: 'application.thinking_out_loud.instructions',
            subPathWithoutLeaf: 'application.thinking_out_loud',
          },
          showSwitchableLayout: true,
        },
        assessment: {
          canShowCheck: true,
          canShowClassroomExplanation: true,
          configurableClassroomExplanation: {
            maxNoOfSteps: 100,
            maxNoOfSubStepsInStep: 100,
            maxSumOfStepsAndSubSteps: 500,
          },
        },
      },
    };
  },
  methods: {
    unApprovedAssetValidation() {
      let countUnApprovedAssets = validateAssetAuthorized(
        this.lessonPlan.data,
        ['assetId'],
        {
          assetId: 'assetApproved',
        },
        ['brief', 'placeholder', 'multimedia'],
        0
      );
      if (countUnApprovedAssets > 0) {
        this.$notify({
          title: 'Failed',
          text: `There are ${countUnApprovedAssets} unapproved assets`,
          type: 'error',
          duration: -1,
        });
        return false;
      }
      return true;
    },
    customValidations() {
      return true;
    },
  },
};
