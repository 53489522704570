<template>
  <v-container>
    <v-card flat>
      <v-card-title>
        List of {{ qrType === QR_TYPES.CONFIDENCE ? 'Confidence' : 'Computer Science' }} QR Codes
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="$emit('add-qr')">Add QR Code</v-btn>
      </v-card-title>

      <v-card-text>
        <v-data-table :headers="headers" :items="filteredQrCodes" class="elevation-1">
          <!-- Search bar -->
          <template slot="top">
            <v-text-field
              v-model="search"
              label="Search"
              class="mx-4"
              append-icon="mdi-magnify"
              placeholder="Search QR Hash, Grade, Subject, Block, BlockId..."
            ></v-text-field>
          </template>

          <!-- QR Hash Slot -->
          <template slot="item.qrHash" slot-scope="{ item }">
            <v-btn icon @click="copyToClipboard(item.qrHash)">
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
            <span>{{ item.qrHash }}</span>
          </template>

          <!-- QR Image Slot with Full-Screen Button -->
          <template slot="item.qrImageUrl" slot-scope="{ item }">
            <div class="d-flex align-center">
              <!-- Display QR Image -->
              <v-img :src="item.qrImageUrl" max-width="100" max-height="100" contain></v-img>

              <!-- Full-Screen Button next to Image -->
              <v-btn icon @click="openImageInNewTab(item.qrImageUrl)">
                <v-icon>mdi-fullscreen</v-icon>
              </v-btn>
            </div>
          </template>

          <!-- Redirect URL Slot with Copy Button -->
          <template slot="item.redirectUrl" slot-scope="{ item }">
            <div class="d-flex align-center">
              <v-btn icon @click="copyToClipboard(item.redirectUrl)">
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
              <span class="ml-2">
                <v-btn text :href="item.redirectUrl" target="_blank">
                  Visit URL
                </v-btn>
              </span>
            </div>
          </template>

          <!-- Confidence Specific Columns -->
          <template slot="item.grade" slot-scope="{ item }">
            <span>
              {{ getGradeAndSubjectDetails(item.grade, item.subject).grade.displayName }}</span
            >
          </template>

          <template slot="item.subject" slot-scope="{ item }">
            <span>{{ getGradeAndSubjectDetails(item.grade, item.subject).subject.name }}</span>
          </template>

          <template v-if="qrType === QR_TYPES.CONFIDENCE" slot="item.year" slot-scope="{ item }">
            <span>{{ item.year }}</span>
          </template>

          <template slot="item.block" slot-scope="{ item }">
            <span v-if="item.block && item.block.bId">{{ item.block.bId }} - </span>
            <span v-if="item.block && item.block.title"> {{ item.block.title }}</span>
          </template>

          <template
            v-if="qrType === QR_TYPES.CONFIDENCE"
            slot="item.question"
            slot-scope="{ item }"
          >
            <span>{{ item.question }}</span>
          </template>

          <!-- Actions Slot (Updated to Edit Button) -->
          <template slot="item.actions" slot-scope="{ item }">
            <v-btn icon @click="editQr(item)">
              <v-icon color="primary">mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { QR_TYPES } from '../../constants/qrManagement';

export default {
  name: 'QRList',
  props: {
    qrType: {
      type: String,
      required: true,
    },
    onEditQRMapping: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      QR_TYPES,
      headers: this.getHeaders(this.qrType),
      search: '', // Holds the search query
    };
  },
  computed: {
    ...mapState({
      qrCodes: (state) => state.qrManagement.qrCodes,
      isLoading: (state) => state.qrManagement.isLoading,
      error: (state) => state.qrManagement.error,
      standards() {
        return this.qrType === QR_TYPES.CONFIDENCE
          ? this.$store.state.qrManagement.confidenceGrades
          : this.$store.state.standards || [];
      },
    }),

    filteredQrCodes() {
      // Function to filter QR codes based on the search term across multiple fields
      return this.qrCodes.filter((item) => {
        // Ensure the QR type matches
        if (this.qrType !== item.qrType) {
          return false;
        }

        const gradeDetails = this.getGradeAndSubjectDetails(item.grade, item.subject);
        const searchTerm = this.search.toLowerCase();

        // Check if any field matches the search term
        return (
          (item.qrHash && item.qrHash.toLowerCase().includes(searchTerm)) ||
          (item.block && item.block.title && item.block.title.toLowerCase().includes(searchTerm)) ||
          (item.block && item.block.bId && item.block.bId.toLowerCase().includes(searchTerm)) ||
          (gradeDetails.grade &&
            gradeDetails.grade.displayName &&
            gradeDetails.grade.displayName.toLowerCase().includes(searchTerm)) ||
          (gradeDetails.subject &&
            gradeDetails.subject.name &&
            gradeDetails.subject.name.toLowerCase().includes(searchTerm))
        );
      });
    },
  },
  methods: {
    ...mapActions([
      'fetchQrCodes',
      'getProductsBoardsStandardsSubjects',
      'fetchConfidenceStandardsSubjects',
    ]),

    getHeaders(qrType) {
      if (qrType === QR_TYPES.CONFIDENCE) {
        return [
          { text: 'QR Hash', value: 'qrHash', sortable: false },
          { text: 'Block', value: 'block', sortable: false },
          { text: 'Grade', value: 'grade', sortable: false },
          { text: 'Subject', value: 'subject', sortable: false },
          { text: 'Year', value: 'year', sortable: false },
          { text: 'Redirect URL', value: 'redirectUrl', sortable: false },
          { text: 'QR Image', value: 'qrImageUrl', sortable: false },
          { text: 'Actions', value: 'actions', sortable: false },
        ];
      }
      return [
        { text: 'QR Hash', value: 'qrHash', sortable: false },
        { text: 'Block', value: 'block', sortable: false },
        { text: 'Grade', value: 'grade', sortable: false },
        { text: 'Subject', value: 'subject', sortable: false },
        { text: 'Redirect URL', value: 'redirectUrl', sortable: false },
        { text: 'QR Image', value: 'qrImageUrl', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ];
    },

    editQr(item) {
      this.onEditQRMapping(item);
    },

    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.$toast('Link copied to clipboard', { type: 'success' });
        })
        .catch(() => {
          this.$toast('Failed to copy link', { type: 'error' });
        });
    },

    openImageInNewTab(imageUrl) {
      const newWindow = window.open(imageUrl, '_blank');
      if (newWindow) {
        newWindow.focus();
      } else {
        this.$toast('Failed to open image in new tab', { type: 'error' });
      }
    },
    getGradeAndSubjectDetails(standardId, subjectId) {
      const result = { grade: {}, subject: {} };

      if (!standardId || !subjectId) return result;

      const grade = this.standards.find((standard) => standard.id === standardId);
      if (grade) {
        result.grade = grade;
        const subject = grade.subjects.find((subject) => subject.id === subjectId);
        if (subject) result.subject = subject;
      }

      return result;
    },
  },
  watch: {
    qrType(newType) {
      this.headers = this.getHeaders(newType); // Update headers when qrType changes
    },
  },
  mounted() {
    this.fetchQrCodes();
    if (this.qrType === QR_TYPES.CONFIDENCE) {
      this.fetchConfidenceStandardsSubjects();
    } else if (this.qrType == QR_TYPES.COMPUTER_SCIENCE) {
      this.getProductsBoardsStandardsSubjects();
    }
  },
};
</script>

<style scoped>
.v-data-table {
  margin-top: 10px;
}
.v-img {
  border: 1px solid #ddd;
}
</style>
